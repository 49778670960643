export const regionsData = {
  countries: [
    {
      countryCode: "AW",
      regions: [],
    },
    {
      countryCode: "AE",
      regions: [{ regionCode: "DUBAI", regionName: "DUBAI" }],
    },
    {
      countryCode: "AR",
      regions: [{ regionCode: "BA", regionName: "Buenos Aires" }],
    },
    {
      countryCode: "AT",
      regions: [{ regionCode: "W", regionName: "Wien" }],
    },
    {
      countryCode: "BR",
      regions: [
        { regionCode: "AC", regionName: "Acre" },
        { regionCode: "AL", regionName: "Alagoas" },
        { regionCode: "AM", regionName: "Amazônia" },
        { regionCode: "AP", regionName: "Amapá" },
        { regionCode: "BA", regionName: "Bahia" },
        { regionCode: "CE", regionName: "Ceará" },
        { regionCode: "DF", regionName: "Distrito Federal" },
        { regionCode: "ES", regionName: "Espírito Santo" },
        { regionCode: "GO", regionName: "Goiás" },
        { regionCode: "MA", regionName: "Maranhão" },
        { regionCode: "MG", regionName: "Minas Gerais" },
        { regionCode: "MS", regionName: "Mato Grosso do Sul" },
        { regionCode: "MT", regionName: "Mato Grosso" },
        { regionCode: "PA", regionName: "Paraná" },
        { regionCode: "PB", regionName: "Paraíba" },
        { regionCode: "PE", regionName: "Pernambuco" },
        { regionCode: "PI", regionName: "Piauí" },
        { regionCode: "PR", regionName: "Paraná" },
        { regionCode: "RJ", regionName: "Rio de Janeiro" },
        { regionCode: "RN", regionName: "Rio Grande do Norte" },
        { regionCode: "RO", regionName: "Rondônia" },
        { regionCode: "RR", regionName: "Roraima" },
        { regionCode: "RS", regionName: "Rio Grande do Sul" },
        { regionCode: "SC", regionName: "Santa Catarina" },
        { regionCode: "SE", regionName: "Sergipe" },
        { regionCode: "SP", regionName: "São Paulo" },
        { regionCode: "TO", regionName: "Tocantins" },
      ],
    },
    {
      countryCode: "CA",
      regions: [
        { regionCode: "BC", regionName: "British Columbia" },
        { regionCode: "MA", regionName: "Manitoba" },
        { regionCode: "ON", regionName: "Ontario" },
        { regionCode: "QU", regionName: "Quebec" },
      ],
    },
    {
      countryCode: "CH",
      regions: [{ regionCode: "TIC", regionName: "Cantone Ticino" }],
    },
    {
      countryCode: "CN",
      regions: [
        { regionCode: "京", regionName: "北京市" },
        { regionCode: "冀", regionName: "河北省" },
        { regionCode: "内蒙古", regionName: "内蒙古自治区" },
        { regionCode: "台", regionName: "台湾省" },
        { regionCode: "吉", regionName: "吉林省" },
        { regionCode: "宁", regionName: "宁夏回族自治区" },
        { regionCode: "川", regionName: "四川省" },
        { regionCode: "新", regionName: "新疆维吾尔自治区" },
        { regionCode: "晋", regionName: "山西省" },
        { regionCode: "桂", regionName: "广西壮族自治区" },
        { regionCode: "沪", regionName: "上海市" },
        { regionCode: "津", regionName: "天津市" },
        { regionCode: "浙", regionName: "浙江省" },
        { regionCode: "渝", regionName: "重庆市" },
        { regionCode: "港", regionName: "香港特别行政区" },
        { regionCode: "湘", regionName: "湖南省" },
        { regionCode: "滇", regionName: "云南省" },
        { regionCode: "澳", regionName: "澳门特别行政区" },
        { regionCode: "琼", regionName: "海南省" },
        { regionCode: "甘", regionName: "甘肃省" },
        { regionCode: "皖", regionName: "安徽省" },
        { regionCode: "粤", regionName: "广东省" },
        { regionCode: "苏", regionName: "江苏省" },
        { regionCode: "藏", regionName: "西藏自治区" },
        { regionCode: "豫", regionName: "河南省" },
        { regionCode: "贵", regionName: "贵州省" },
        { regionCode: "赣", regionName: "江西省" },
        { regionCode: "辽", regionName: "辽宁省" },
        { regionCode: "鄂", regionName: "湖北省" },
        { regionCode: "闽", regionName: "福建省" },
        { regionCode: "陕", regionName: "陕西省" },
        { regionCode: "青", regionName: "青海省" },
        { regionCode: "鲁", regionName: "山东省" },
        { regionCode: "黑", regionName: "黑龙江省" },
      ],
    },
    {
      countryCode: "DE",
      regions: [
        { regionCode: "BB", regionName: "Brandenburg" },
        { regionCode: "BE", regionName: "Berlin" },
        { regionCode: "BW", regionName: "Baden-Württemberg" },
        { regionCode: "BY", regionName: "Bayern" },
        { regionCode: "HB", regionName: "Bremen" },
        { regionCode: "HE", regionName: "Hessen" },
        { regionCode: "MV", regionName: "Mecklenburg-Vorpommern" },
        { regionCode: "NI", regionName: "Niedersachsen" },
        { regionCode: "NW", regionName: "Nordrhein-Westfalen" },
        { regionCode: "RP", regionName: "Rheinland-Pfalz" },
        { regionCode: "SH", regionName: "Schleswig-Holstein" },
        { regionCode: "SL", regionName: "Saarland" },
        { regionCode: "SN", regionName: "Sachsen" },
        { regionCode: "ST", regionName: "Sachsen-Anhalt" },
        { regionCode: "TH", regionName: "Thüringen" },
      ],
    },
    {
      countryCode: "FR",
      regions: [
        { regionCode: "FR-ARA", regionName: "Auvergne-Rhône-Alpes" },
        { regionCode: "FR-BFC", regionName: "Bourgogne-Franche-Comté" },
        { regionCode: "FR-BL", regionName: "Saint Barthelemy" },
        { regionCode: "FR-BRE", regionName: "Bretagne" },
        { regionCode: "FR-COR", regionName: "Corse" },
        { regionCode: "FR-CVL", regionName: "Centre-Val de Loire" },
        { regionCode: "FR-GES", regionName: "Grand Est" },
        { regionCode: "FR-GUA", regionName: "Guadeloupe" },
        { regionCode: "FR-GUF", regionName: "Martinique" },
        { regionCode: "FR-HDF", regionName: "Hauts-de-France" },
        { regionCode: "FR-IDF", regionName: "Île-de-France" },
        { regionCode: "FR-MF", regionName: "Saint Martin" },
        { regionCode: "FR-MTQ", regionName: "Guyane" },
        { regionCode: "FR-NAQ", regionName: "Nouvelle-Aquitaine" },
        { regionCode: "FR-NC", regionName: "Nouvelle Caledonie" },
        { regionCode: "FR-NOR", regionName: "Normandie" },
        { regionCode: "FR-OCC", regionName: "Occitanie" },
        { regionCode: "FR-PAC", regionName: "Provence-Alpes-Côte d'Azur" },
        { regionCode: "FR-PDL", regionName: "Pays de la Loire" },
        { regionCode: "FR-PF", regionName: "Polynesie Francaise" },
        { regionCode: "FR-PM", regionName: "St Pierre et Miquelon" },
        { regionCode: "FR-RE", regionName: "La Reunion" },
        { regionCode: "FR-WF", regionName: "Wallis et Futuna" },
        { regionCode: "FR-YT", regionName: "Mayotte" },
        { regionCode: "AL", regionName: "Alsace" },
        { regionCode: "AQ", regionName: "Aquitaine" },
        { regionCode: "AU", regionName: "Auvergne" },
        { regionCode: "BN", regionName: "Basse-Normandie" },
        { regionCode: "BO", regionName: "Bourgogne" },
        { regionCode: "BR", regionName: "Bretagne" },
        { regionCode: "CA", regionName: "Champagne-Ardenne" },
        { regionCode: "CE", regionName: "Centre" },
        { regionCode: "CO", regionName: "Corse" },
        { regionCode: "FC", regionName: "Franche-Comté" },
        { regionCode: "GA", regionName: "Guadeloupe" },
        { regionCode: "GU", regionName: "Guyane" },
        { regionCode: "HN", regionName: "Haute-Normandie" },
        { regionCode: "IF", regionName: "Île-de-France" },
        { regionCode: "Ile-de-Fra", regionName: "Île-de-France" },
        { regionCode: "LI", regionName: "Limousin" },
        { regionCode: "LO", regionName: "Lorraine" },
        { regionCode: "LR", regionName: "Languedoc-Roussillon" },
        { regionCode: "MA", regionName: "Martinique" },
        { regionCode: "MP", regionName: "Midi-Pyrénées" },
        { regionCode: "MY", regionName: "Mayotte" },
        { regionCode: "NP", regionName: "Nord-Pas-de-Calais" },
        { regionCode: "PC", regionName: "Provence-Alpes-Côte d’Azur" },
        { regionCode: "PI", regionName: "Picardie" },
        { regionCode: "PL", regionName: "Pays de la Loire" },
        { regionCode: "RA", regionName: "Rhône-Alpes" },
        { regionCode: "RE", regionName: "Réunion" },
      ],
    },
    {
      countryCode: "GB",
      regions: [
        { regionCode: "CD", regionName: "Crown Dependencies" },
        { regionCode: "EN", regionName: "England" },
        { regionCode: "NI", regionName: "Northern Ireland" },
        { regionCode: "SC", regionName: "Scotland" },
        { regionCode: "WA", regionName: "Wales" },
      ],
    },
    {
      countryCode: "IN",
      regions: [
        { regionCode: "Andhra", regionName: "Andhra Pradesh" },
        { regionCode: "Chandigarh", regionName: "Chandigarh" },
        { regionCode: "Delhi", regionName: "Delhi" },
        { regionCode: "Karnataka", regionName: "Karnataka" },
        { regionCode: "Mahara", regionName: "Maharastra" },
        { regionCode: "Pondicherr", regionName: "Pondicherry" },
        { regionCode: "TM", regionName: "Tamilnadu" },
      ],
    },
    {
      countryCode: "IT",
      regions: [
        { regionCode: "PIE", regionName: "Piemonte" },
        { regionCode: "LOM", regionName: "Lombardia" },
        { regionCode: "VEN", regionName: "Veneto" },
        { regionCode: "LIG", regionName: "Liguria" },
        { regionCode: "TOS", regionName: "Toscana" },
        { regionCode: "UMB", regionName: "Umbria" },
        { regionCode: "MAR", regionName: "Marche" },
        { regionCode: "LAZ", regionName: "Lazio" },
        { regionCode: "ABR", regionName: "Abruzzo" },
        { regionCode: "MOL", regionName: "Molise" },
        { regionCode: "CAM", regionName: "Campania" },
        { regionCode: "PUG", regionName: "Puglia" },
        { regionCode: "BAS", regionName: "Basilicata" },
        { regionCode: "CAL", regionName: "Calabria" },
        { regionCode: "SIC", regionName: "Sicilia" },
        { regionCode: "SAR", regionName: "Sardegna" },
        { regionCode: "MI", regionName: "MILANO" },
        { regionCode: "FLO", regionName: "FLORENCE" },
        { regionCode: "VDA", regionName: "Valle d'Aosta/Vallée d'Aoste" },
        { regionCode: "EMR", regionName: "Emilia-Romagna" },
        { regionCode: "FVG", regionName: "Friuli-Venezia Giulia" },
        { regionCode: "TAA", regionName: "Trentino-Alto Adige/Südtirol" },
      ],
    },
    {
      countryCode: "JP",
      regions: [
        { regionCode: "京都府", regionName: "" },
        { regionCode: "北海道", regionName: "" },
        { regionCode: "大阪府", regionName: "" },
        { regionCode: "東京都", regionName: "" },
        { regionCode: "福岡県", regionName: "" },
        { regionCode: "群馬県", regionName: "" },
        { regionCode: "長崎県", regionName: "" },
      ],
    },
    {
      countryCode: "MC",
      regions: [{ regionCode: "FR-PAC", regionName: "Monaco" }],
    },
    {
      countryCode: "MX",
      regions: [
        { regionCode: "AG", regionName: "Aguas Canlientes" },
        { regionCode: "BC", regionName: "Baja California" },
        { regionCode: "BS", regionName: "Baja California Sur" },
        { regionCode: "CH", regionName: "Chihuahua" },
        { regionCode: "CL", regionName: "Colima" },
        { regionCode: "CM", regionName: "Campeche" },
        { regionCode: "CO", regionName: "Coahuila" },
        { regionCode: "CS", regionName: "Chiapas" },
        { regionCode: "DF", regionName: "Distrito Federal" },
        { regionCode: "DG", regionName: "Durango" },
        { regionCode: "GR", regionName: "Guerrero" },
        { regionCode: "GT", regionName: "Guanajuato" },
        { regionCode: "HG", regionName: "Hidalgo" },
        { regionCode: "JAL", regionName: "Jalisco" },
        { regionCode: "ME", regionName: "Estado de Mexico" },
        { regionCode: "MN", regionName: "Michoacán" },
        { regionCode: "MS", regionName: "Morelos" },
        { regionCode: "NL", regionName: "Nueva León" },
        { regionCode: "NT", regionName: "Nayarit" },
        { regionCode: "OC", regionName: "Oaxaca" },
        { regionCode: "PL", regionName: "Puebla" },
        { regionCode: "QO", regionName: "Querétaro" },
        { regionCode: "QR", regionName: "Quintana Roo" },
        { regionCode: "SL", regionName: "Sinaloa" },
        { regionCode: "SP", regionName: "San Luis Potosí" },
        { regionCode: "SR", regionName: "Sonora" },
        { regionCode: "TC", regionName: "Tabasco" },
        { regionCode: "TL", regionName: "Tlaxcala" },
        { regionCode: "TS", regionName: "Tamaulipas" },
        { regionCode: "VZ", regionName: "Veracruz" },
        { regionCode: "YN", regionName: "Yucatán" },
        { regionCode: "ZS", regionName: "Zacatecas" },
      ],
    },
    {
      countryCode: "MY",
      regions: [
        { regionCode: "JHR", regionName: "Johor" },
        { regionCode: "KDH", regionName: "Kedah" },
        { regionCode: "KTN", regionName: "Kelantan" },
        { regionCode: "KUL", regionName: "Kuala Lumpur" },
        { regionCode: "LBN", regionName: "Labuan" },
        { regionCode: "MLK", regionName: "Melaka" },
        { regionCode: "NSN", regionName: "Negeri Sembilan" },
        { regionCode: "PHG", regionName: "Pahang" },
        { regionCode: "PJY", regionName: "Putrajaya" },
        { regionCode: "PLS", regionName: "Perlis" },
        { regionCode: "PNG", regionName: "Penang" },
        { regionCode: "PRK", regionName: "Perak" },
        { regionCode: "SBH", regionName: "Sabah" },
        { regionCode: "SGR", regionName: "Selangor" },
        { regionCode: "SWK", regionName: "Sarawak" },
        { regionCode: "TRG", regionName: "Terengganu" },
      ],
    },
    {
      countryCode: "NL",
      regions: [{ regionCode: "NH", regionName: "North Holland" }],
    },
    {
      countryCode: "RU",
      regions: [
        { regionCode: "50", regionName: "Московская" },
        { regionCode: "77", regionName: "Москва" },
      ],
    },
    {
      countryCode: "SA",
      regions: [
        { regionCode: "MDM", regionName: "Al Madinah" },
        { regionCode: "MKM", regionName: "Makkah" },
        { regionCode: "QSM", regionName: "Al Qassim" },
        { regionCode: "RYH", regionName: "Ar Riyadh" },
        { regionCode: "SHQ", regionName: "Ash-Sharqiyah (eastern region)" },
        { regionCode: "TBK", regionName: "Tabuk" },
      ],
    },
    {
      countryCode: "VA",
      regions: [{ regionCode: "", regionName: "Vatican City" }],
    },
    {
      countryCode: "TH",
      regions: [
        { regionCode: "", regionName: "Bangkok" },
        { regionCode: "", regionName: "Nonthaburi" },
        { regionCode: "", regionName: "Songkhla" },
        { regionCode: "", regionName: "Tak" },
      ],
    },
    {
      countryCode: "US",
      regions: [
        { regionCode: "OH", regionName: "Ohio" },
        { regionCode: "GA", regionName: "Georgia" },
        { regionCode: "AK", regionName: "Alaska" },
        { regionCode: "AL", regionName: "Alabama" },
        { regionCode: "AR", regionName: "Arkansas" },
        { regionCode: "AS", regionName: "American Samoa" },
        { regionCode: "AZ", regionName: "Arizona" },
        { regionCode: "CA", regionName: "California" },
        { regionCode: "CO", regionName: "Colorado" },
        { regionCode: "CT", regionName: "Connecticut" },
        { regionCode: "DC", regionName: "District of Columbia" },
        { regionCode: "DE", regionName: "Delaware" },
        { regionCode: "FL", regionName: "Florida" },
        { regionCode: "FM", regionName: "Federated States of Micronesia" },
        { regionCode: "GU", regionName: "Guam" },
        { regionCode: "HI", regionName: "Hawaii" },
        { regionCode: "IA", regionName: "Iowa" },
        { regionCode: "ID", regionName: "Idaho" },
        { regionCode: "IL", regionName: "Illinois" },
        { regionCode: "IN", regionName: "Indiana" },
        { regionCode: "KS", regionName: "Kansas" },
        { regionCode: "KY", regionName: "Kentucky" },
        { regionCode: "LA", regionName: "Louisiana" },
        { regionCode: "MA", regionName: "Massachusetts" },
        { regionCode: "MD", regionName: "Maryland" },
        { regionCode: "ME", regionName: "Maine" },
        { regionCode: "MH", regionName: "Marshall Islands" },
        { regionCode: "MI", regionName: "Michigan" },
        { regionCode: "MN", regionName: "Minnesota" },
        { regionCode: "MO", regionName: "Missouri" },
        { regionCode: "MP", regionName: "Northern Mariana Islands" },
        { regionCode: "MS", regionName: "Mississippi" },
        { regionCode: "MT", regionName: "Montana" },
        { regionCode: "NC", regionName: "North Carolina" },
        { regionCode: "ND", regionName: "North Dakota" },
        { regionCode: "NE", regionName: "Nebraska" },
        { regionCode: "NH", regionName: "New Hampshire" },
        { regionCode: "NJ", regionName: "New Jersey" },
        { regionCode: "NM", regionName: "New Mexico" },
        { regionCode: "NV", regionName: "Nevada" },
        { regionCode: "NY", regionName: "New York" },
        { regionCode: "OK", regionName: "Oklahoma" },
        { regionCode: "OR", regionName: "Oregon" },
        { regionCode: "PA", regionName: "Pennsylvania" },
        { regionCode: "PR", regionName: "Puerto Rico" },
        { regionCode: "PW", regionName: "Palau" },
        { regionCode: "RI", regionName: "Rhode Island" },
        { regionCode: "SC", regionName: "South Carolina" },
        { regionCode: "SD", regionName: "South Dakota" },
        { regionCode: "TN", regionName: "Tennessee" },
        { regionCode: "TX", regionName: "Texas" },
        { regionCode: "UT", regionName: "Utah" },
        { regionCode: "VA", regionName: "Virginia" },
        { regionCode: "VI", regionName: "Virgin Islands" },
        { regionCode: "VT", regionName: "Vermont" },
        { regionCode: "WA", regionName: "Washington" },
        { regionCode: "WI", regionName: "Wisconsin" },
        { regionCode: "WV", regionName: "West Virginia" },
        { regionCode: "WY", regionName: "Wyoming" },
      ],
    },
  ],
};

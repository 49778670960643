import API from "../index";
import { TPriceResponse, TRequestPriceCallback } from "./interfaces/price";

export const getPrice: TRequestPriceCallback = (
  productCode,
  clientCode,
  currencyCode,
  priceListProduct,
  priceListSample
) => {
  return API.get<TPriceResponse>(`/v2/products/${productCode}/prices`, {
    params: {
      client_code: clientCode,
      currency_code: currencyCode,
      product_pricebook_code: priceListProduct,
      sample_pricebook_code: priceListSample,
    },
  }).then(({ data }) => data);
};

import axios from "axios";

import Cookie, { tokenCookieName } from "../Cookie";
import { getExpirationDate } from "../token";

import IAPI, { TAuthTokenCallback } from "./interfaces";

const API: IAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 180000,
  headers: {
    "Content-Type": "application/json",
    "X-source": "PWA",
  },
});

API.getTokenFromCookie = () => Cookie.get(tokenCookieName);

API.setAuthToken = ((token, tokenExpirationField, tokenTimestampShift) => {
  Cookie.set(tokenCookieName, token, {
    expires: getExpirationDate(
      token,
      tokenExpirationField,
      tokenTimestampShift
    ),
  });
  API.defaults.headers!.common["Authorization"] = `Bearer ${token}`;
}) as TAuthTokenCallback;

API.forgetAuthToken = () => {
  Cookie.delete(tokenCookieName);
  delete API.defaults.headers!.common["Authorization"];
};

API.forgetAuth = () => {
  API.forgetAuthToken!();
};

export default API;

import React, { useContext, useEffect } from "react";
import Styles from "./styles.module.scss";
import ERoutes from "../../../routers/NotAuthRouter/routes";
import { useHistory } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import {
  LocaleConsumer,
  LocaleContext,
} from "../../../providers/LocaleProvider";
import Button from "../../../components/Button";
import BackgroundImageLayout from "../../../layouts/BackgroundImageLayout";
import backgroundImage from "../../../globals/assets/metier_papier_peint.png";
import LoginForm from "../../../forms/LoginForm";
import EGlobalRoutes from "../../../routers/AppRouter/routes";
import { getLogin, getUser } from "../../../helpers/API/requests/login";
import API from "../../../helpers/API";
import { AuthContext } from "../../../providers/AuthProvider";
import { getClientInfo } from "../../../helpers/API/requests/clients";
import { notification } from "antd";
import * as AuthRoutes from "../../../routers/AuthRouter/routes";
import {
  ShowroomsConsumer,
  ShowroomsContext,
} from "../../../providers/ShowroomProvider";
import HTMLRender from "../../../components/HTMLRender";
import { OnboardingContext } from "../../../providers/OnboardingProvider";
import { InvalidateContext } from "../../../providers/InvalidateProvider";

const Login: React.FC = () => {
  let query = useQuery();
  const showroom = query.get("showroom");
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const localeContext = useContext(LocaleContext);
  const showroomsContext = useContext(ShowroomsContext);
  const { canSkip, resetSkip, canSkipNextTime } = useContext(OnboardingContext);
  const { invalidate } = useContext(InvalidateContext);

  useEffect(() => {
    if (
      !showroom ||
      showroom === "null" ||
      !showroomsContext.showrooms.find((elem) => elem.showroomCode === showroom)
    ) {
      history.push(ERoutes.Home);
    }
    invalidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    getLogin(email, password)
      .then(({ token }) => {
        const user = getUser(token);
        console.log(user);

        if (user.skipOnboarding) {
          canSkipNextTime();
        } else {
          resetSkip();
        }
        if (!(user.isRep || user.isManager)) {
          getClientInfo({
            accountCode: user!.clientAccountNumber,
            authToken: token,
          })
            .then(({ data }) => {
              if (!data.clientInfo) {
                notification["error"]({
                  placement: "bottomLeft",
                  message: localeContext.getTranslation(
                    "login_page.error_fetching_client"
                  ),
                });
                return;
              }
              API.setAuthToken!(token);
              history.push(
                canSkip.current
                  ? {
                      pathname: AuthRoutes.default.Home,
                    }
                  : {
                      pathname: AuthRoutes.default.Onboarding,
                      state: {
                        pathname: AuthRoutes.default.Home,
                      },
                    }
              );
              authContext.login(token, {
                ...user,
                client: data.clientInfo,
                showroom: showroom!,
              });
            })
            .catch(() => {
              notification["error"]({
                placement: "bottomLeft",
                message: localeContext.getTranslation(
                  "login_page.error_fetching_client"
                ),
              });
              API.forgetAuthToken!();
            });
        } else {
          history.push({
            pathname: ERoutes.SelectClient,
            state: { token: token, showroom: showroom },
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          notification["error"]({
            placement: "bottomLeft",
            message: localeContext.getTranslation("login_page.not_found"),
          });
        } else {
          notification["error"]({
            placement: "bottomLeft",
            message: localeContext.getTranslation("login_page.error_login"),
          });
        }
      });
  };

  const formatShowroomName = (name?: string) => {
    if (!name) return name;
    name = name.toLowerCase();
    name = name.charAt(0).toUpperCase() + name.substring(1);
    for (let i = 1; i < name.length; i++)
      if (name[i - 1] === " " || name[i - 1] === "-")
        name =
          name?.substring(0, i) +
          name[i].toUpperCase() +
          name?.substring(i + 1);
    return name;
  };

  return (
    <LocaleConsumer>
      {({ getTranslation }) => (
        <ShowroomsConsumer>
          {({ showrooms }) => (
            <BackgroundImageLayout image={backgroundImage}>
              <div className={Styles["login"]}>
                <h1>{getTranslation("login_page.welcome")}</h1>
                <h1 className={`title ${Styles["login__title"]}`}>
                  {formatShowroomName(
                    showrooms.find((elem) => elem.showroomCode === showroom)
                      ?.showroomDescription
                  )}
                </h1>
                <h2>
                  <HTMLRender>
                    {getTranslation("login_page.description")}
                  </HTMLRender>
                </h2>
                <LoginForm onSubmit={onSubmit} />
                <Button
                  type="button"
                  onClick={() => {
                    window.location.href = getTranslation(
                      "login_page.url_signup"
                    );
                  }}
                  styleType="outlined"
                  size="large"
                  label={getTranslation("login_page.signup")}
                  className={Styles["signup"]}
                />
                <Button
                  type="button"
                  onClick={() => {
                    window.location.href = getTranslation(
                      "login_page.url_password_forgotten"
                    );
                  }}
                  styleType="text"
                  size="large"
                  label={getTranslation("login_page.password_forgotten")}
                  className={Styles["login__links"]}
                />
                <Button
                  type="button"
                  link={EGlobalRoutes.PrivacyPolicy}
                  styleType="text"
                  size="large"
                  label={getTranslation("login_page.privacy_policy")}
                />
              </div>
            </BackgroundImageLayout>
          )}
        </ShowroomsConsumer>
      )}
    </LocaleConsumer>
  );
};

export default Login;

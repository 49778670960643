import API from "../index";
import {
  TAddressResponse,
  TRequestAddressCallback,
} from "./interfaces/address";

export const getAddresses: TRequestAddressCallback = (clientCode) => {
  return API.get<TAddressResponse>(`/v2/clients/${clientCode}/addresses`).then(
    ({ data }) => data
  );
};

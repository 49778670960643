import React, { useContext } from "react";
import Styles from "./styles.module.scss";
import { Form } from "antd";
import Button from "../../components/Button";
import { LocaleConsumer, LocaleContext } from "../../providers/LocaleProvider";
import InputText from "../../components/InputText";
import { IFilterClientsForm } from "./interfaces";

const FilterClientsForm: React.FC<IFilterClientsForm> = ({
  onFilter,
  clientsIsLoading,
}) => {
  const [form] = Form.useForm();
  const [clientName, setClientName] = React.useState("");
  const [clientCode, setClientCode] = React.useState("");
  const [clientEmail, setClientEmail] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const localeContext = useContext(LocaleContext);

  const onFinish = ({
    clientName,
    clientCode,
    clientEmail,
  }: {
    clientName: string;
    clientCode: string;
    clientEmail: string;
  }) => {
    if (clientName === "" && clientCode === "" && clientEmail === "") {
      setErrorText(
        localeContext.getTranslation(
          "select_client_page.filters.empty_submit_error"
        )
      );
      return;
    }
    setErrorText("");
    onFilter({
      clientName: clientName.length > 0 ? clientName : undefined,
      clientCode: clientCode.length > 0 ? clientCode : undefined,
      clientEmail: clientEmail.length > 0 ? clientEmail : undefined,
    });
  };

  return (
    <LocaleConsumer>
      {({ getTranslation }) => (
        <Form
          form={form}
          onFinish={onFinish}
          className={Styles["filterClients__form"]}
          action=""
        >
          <h1 className={Styles["filterClients__h1"]}>
            {getTranslation("select_client_page.no_filter")}
          </h1>
          <InputText
            label={getTranslation("select_client_page.filters.client_name")}
            fieldName="clientName"
            value={clientName}
            setValue={setClientName}
          />
          <InputText
            label={getTranslation("select_client_page.filters.client_code")}
            fieldName="clientCode"
            value={clientCode}
            setValue={setClientCode}
          />
          <InputText
            label={getTranslation("select_client_page.filters.client_email")}
            fieldName="clientEmail"
            value={clientEmail}
            setValue={setClientEmail}
          />
          <Button
            type="submit"
            styleType="plain"
            size="large"
            label={getTranslation("select_client_page.filters.submit")}
            isLoading={clientsIsLoading}
            disabled={clientsIsLoading}
          />
          {errorText !== "" && <h2>{errorText}</h2>}
        </Form>
      )}
    </LocaleConsumer>
  );
};

export default FilterClientsForm;

import React from "react";

export type TClient = {
  accountCode?: string;
  accountName?: string;
  status?: string;
  accountCategory?: string;
  accountCategoryDescription?: string;
  street?: string;
  streetAddition?: string;
  postalCodeAndCity?: string;
  countryName?: string;
  city?: string;
  postalCode?: string;
  stateOrDepartment?: string;
  countryCode?: string;
  countryCodeName?: string;
  currencyCode?: string;
  accountLanguage?: string;
  accountMainContact?: string;
  accountSecondaryContact?: string;
  mainPhone?: string;
  secondaryPhone?: string;
  repsCode?: string;
  repsName?: string;
  clientServiceCode?: string;
  pierreFreySubsidiary?: string;
  priceListProduct?: string;
  priceListSample?: string;
  discountModel?: string;
  emailTypeCode?: string;
  email?: string[];
};

export type TUser = {
  /**
   * Showroom
   */
  showroom?: string;

  /**
   * User's username
   */
  username?: string;

  /**
   * User's firstname
   */
  firstname?: string;

  /**
   * User's lastname
   */
  lastname?: string;

  /**
   * User's email
   */
  email?: string;

  /**
   * User's client number
   */
  clientAccountNumber?: string;

  /**
   * Is an user?
   */
  isClient: boolean;

  /**
   * Is a rep?
   */
  isRep: boolean;

  /**
   * Is a manager?
   */
  isManager: boolean;

  /**
   * User's array of commercial rep code
   */
  commercialRepCode?: string[];

  /**
   * User's array of manager rep code
   */
  managerRepCode?: string[];

  /**
   * active client
   */
  client?: TClient;

  /**
   * User can skip onboarding next time.
   */
  skipOnboarding: boolean;
};

export interface IAuthState {
  /**
   * User object
   */
  user: TUser | null;
}

export enum EAuthActionType {
  /**
   * Set User on provider
   */
  Set = "AUTH_SET_USER",

  /**
   * Update User on provider
   */
  Edit = "AUTH_EDIT_USER",
}

export type TAuthAction = {
  /**
   * type of reducer's action
   */
  type: EAuthActionType;

  /**
   * reducer's action parameter need to set or update user
   */
  user: TUser | null;
};

export type TAuthReducer = (
  prevState: IAuthState,
  action: TAuthAction
) => IAuthState;

export type TAuthTypeBasicValues = {
  /**
   * Basic authentification's username
   */
  username: string;

  /**
   * Basic authentification's password
   */
  password: string;
};

export type TAuthTypeBTokenValues = string;

export type TAuthTypeValues = TAuthTypeBasicValues | TAuthTypeBTokenValues;

export type TLoginCallback = (values: TAuthTypeValues, user: TUser) => void;

export type TLogoutCallback = () => void;

export type TEditUserCallback = (user: TUser) => void;

export interface IAuthProps extends IAuthState {
  /**
   * Login callback use to setup open new connection
   */
  login: TLoginCallback;

  /**
   * Logout callback use to setup close current connection
   */
  logout: TLogoutCallback;

  /**
   * Callback use to update current user datas.
   */
  editUser: TEditUserCallback;
}

export type TAuthRetrieveUserCallback = (token: string) => TUser;

export type TAuthProps = {
  /**
   * Callback use to retrieve current user from token stored on cookie.
   */
  onRetrieveUserFromCookie: TAuthRetrieveUserCallback;

  /**
   * Name of expiration field use by the JWT token, default 'exp'
   */
  tokenExpirationField?: string;

  /**
   * Shift between the way Timestamps is interpreting.
   * PHP use seconds as based while javascript is on milliseconds.
   * default (1000).
   */
  tokenTimestampShift?: number;

  /**
   * Component render during loading.
   */
  renderLoading?: React.ReactElement<any, any> | null;
};

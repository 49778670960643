import React, { useEffect } from "react";
import { Form, Select } from "antd";
import FloatLabel from "../FloatLabel";
import { IInputSelect } from "./interfaces";
import Styles from "./styles.module.scss";

const InputSelect: React.FC<IInputSelect> = ({
  label,
  value,
  setValue,
  fieldName,
  error = null,
  dependencies,
  rules,
  className,
  help,
  options,
  readOnly = false,
}) => {
  const validation = React.useMemo(() => {
    if (error !== null) {
      return {
        validateStatus: "error" as "error",
        help: error,
      };
    }
    return undefined;
  }, [error]);

  const _label = React.useMemo(() => {
    const rule = rules?.find((_rule: any) => _rule.required === true);

    return rule ? `${label}*` : label;
  }, [label, rules]);

  return (
    <FloatLabel
      label={_label}
      value={"float"}
      className={className}
      help={help}
    >
      <Form.Item
        name={fieldName}
        initialValue={value}
        dependencies={dependencies}
        rules={rules}
        validateStatus={validation?.validateStatus}
        help={validation?.help}
      >
        <Select
          key={JSON.stringify(options)}
          onChange={setValue}
          value={value}
          disabled={readOnly}
          className={readOnly ? Styles["readOnly"] : undefined}
        >
          {options.map(({ label: optLabel, value: optValue }) => (
            <Select.Option
              key={optValue.toString()}
              value={optValue.toString()}
            >
              {optLabel}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </FloatLabel>
  );
};

export default InputSelect;

import {
  TShowroomsResponse,
  TRequestShowroomsCallback,
} from "./interfaces/showrooms";
import API from "../index";

export const getActivePWAShowrooms: TRequestShowroomsCallback = () => {
  return API.get<TShowroomsResponse>(
    "/v2/showrooms?showroom_active_code=1&showroom_active_code_for_application=1"
  ).then(({ data }) => {
    return { data: data.data.filter((elem) => elem.applicationCode === "PWA") };
  });
};
